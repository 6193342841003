.NotEligible {

    max-width: 600px;
    margin: auto;

    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 20px #00000014;
    margin-top: 20px;
    margin-bottom: 80px;
}